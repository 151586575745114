import { Card, Divider, Grid, IconButton, Switch } from '@mui/material'
import Header from './components/Tabs'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Docs from "../docs"
import Category from "../Category"
import SubCategory from "../SubCategory"
function index() {
    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <Grid container mt={10}>
                    <Grid item xs={12}>
                        <Header elements={[<Category />, <SubCategory />, <Docs />]} />
                    </Grid>
                </Grid>
            </DashboardLayout>
        </>
    )
}

export default index
