
import Home from './Home'
import View from './View'

const routes = [
  {
    type: 'collapse',
    key: 'home',
    route: '/',
    component: <Home />,
  },
  {
    type: 'collapse',
    key: 'home',
    route: '/:languageId',
    component: <View />,
  },
]

export default routes
