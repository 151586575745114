/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import EditModal from '../../EditModal'
import { useNavigate } from 'react-router-dom'
import FreeAppModel from '../../FreeAppModel'
import Select from 'react-select';
import ViewModel from '../../ViewModal'
export default function DataTableData() {
  const AppService = useAppServices()
  const navigate = useNavigate()
  const [List, setList] = useState([])
  const [products, setproducts] = useState([])
  const [Categories, setCategories] = useState([])
  const [loader, setloader] = useState(true)

  const options = [
    { value: 'Planned', label: 'Planned' },
    { value: 'Inprogress', label: 'Inprogress' },
    { value: 'completed', label: 'completed' },
  ];
  const getApps = async () => {
    // alert('called')
    const { response, error } = await AppService.updateRequest.filter({
      query: 'request_type=new_app'
    })
    console.log(response, 'responseresponseresponseresponseresponseresponseresponseresponseresponseresponseresponseresponse')
    // return 0
    if (response) {
      setList(response.data)
      setloader(false)

      // setList(response.data)
    } else {
      setloader(false)
    }
  }
  const onLoad = () => {
    getApps()
  }
  const handleDelete = async (id) => {
    const { response } = await AppService.updateRequest.delete({
      query: `_id=${id}`,
    })
    if (response) onLoad()
  }

  const handleClickUpTask = async (request) => {
    const payload = request
    console.log(payload)
    const { response } = await AppService.updateRequest.create_task({
      payload: payload,
    })
    if (response) onLoad()
  }
  useEffect(onLoad, [])
  return {
    loader: loader,
    products: products,
    Categories: Categories,
    handleRefresh: onLoad,
    dataTableData: {
      columns: [
        { Header: 'app_name', accessor: 'app_name' },
        { Header: 'title', accessor: 'title' },
        // { Header: 'description', accessor: 'description' },
        { Header: 'company_id', accessor: 'company_id' },
        { Header: 'app_type', accessor: 'app_type' },
        { Header: 'type', accessor: 'type' },
        { Header: 'Send To Click Up', accessor: 'send_to_click_up' },
        { Header: 'Action', accessor: 'action' },
      ],

      rows: List.map((data) => ({
        title: data.title,
        app_name: data.app_name,
        // description: data.description,
        company_id: data.company_id,
        app_type: data.app_type,
        type: data.type,
        send_to_click_up: <>
          <MDButton
            variant="gradient"
            color="info"
            type="button"
            size="small"
            onClick={() => handleClickUpTask(data)}
          >
            Send to click up
          </MDButton>
        </>,
        action: (

          <MDBox>
            {/* <FreeAppModel
              products={products}
              data={data}
              handleRefresh={onLoad}
              Categories={Categories}
            /> */}
            {/* <EditModal
              products={products}
              data={data}
              handleRefresh={onLoad}
              Categories={Categories}
            /> */}
            <ViewModel data={data} />

            <MDButton
              variant="contained"
              color="error"
              size="small"
              sx={{ marginLeft: 2 }}
              onClick={() => handleDelete(data._id)}
            >
              Delete
            </MDButton>
          </MDBox>
        ),
      })),
    },
  }
}
