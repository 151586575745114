/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function DataTableData() {
  const AppService = useAppServices()
  const navigate = useNavigate()
  const [List, setList] = useState([])
  const [loader, setloader] = useState(true)

  const getAppLocations = async () => {
    const { response } = await AppService.applocations.get()
    console.log(response, 'getAppLocations')
    if (response) {
      setList(response.data)
      setloader(false)
    } else {
      setList([])
      setloader(false)
    }
  }
  const onLoad = () => {
    getAppLocations()
  }

  useEffect(onLoad, [])
  return {
    loader: loader,
    handleRefresh: onLoad,
    dataTableData: {
      columns: [
        { Header: 'Name', accessor: 'name' },
        { Header: 'email', accessor: 'email' },
        { Header: 'phone', accessor: 'phone' },
        { Header: 'LocationId', accessor: 'location_id' },
        { Header: 'CompanyId', accessor: 'company_id' },
        { Header: 'UserId', accessor: 'user_id' },
        { Header: 'address', accessor: 'address' },
        { Header: 'website', accessor: 'website' },
        { Header: 'customer_type', accessor: 'customer_type' },
      ],

      rows: List.map((data) => ({
        location_id: data?.locationId,
        company_id: data?.Companyid,
        user_id: data?.user_id,
        name: data?.Name,
        email: data?.email,
        phone: data?.phone,
        address: data?.address,
        website: data?.website,
        customer_type: data?.customer_type,
      })),
    },
  }
}