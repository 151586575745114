/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function DataTableData() {
  const AppService = useAppServices()
  const navigate = useNavigate()
  const [List, setList] = useState([])
  const [superadmin_apps, setsuperadmin_apps] = useState([])
  const [loader, setloader] = useState(true)

  const getAppCompanies = async () => {
    const { response } = await AppService.appinstalls.get()
    console.log(response, 'getAppCompanies')
    if (response) {
      setList(response.data)
      setsuperadmin_apps(response.superadmin_apps)
      setloader(false)
    } else {
      setList([])
      setloader(false)
    }
  }
  const onLoad = () => {
    getAppCompanies()
  }
  const filterPlanId = (Plan_ID) => {
    const filter_monthly = superadmin_apps.filter((app) => app.monthly_id == Plan_ID)
    const filter_yearly = superadmin_apps.filter((app) => app.yearly_id == Plan_ID)
    if (filter_monthly[0]) {
      const filter_price = superadmin_apps.filter((app) => app.monthly_id == Plan_ID)[0]?.monthly_price
      return filter_price

    } else {
      const filter_price = superadmin_apps.filter((app) => app.yearly_id == Plan_ID)[0]?.yearly_price
      return filter_price

    }
  }

  useEffect(onLoad, [])
  return {
    loader: loader,
    handleRefresh: onLoad,
    dataTableData: {
      columns: [
        { Header: 'App_Name', accessor: 'App_Name' },
        { Header: 'App_ID', accessor: 'App_ID' },
        { Header: 'GHL_USER_ID', accessor: 'GHL_USER_ID' },
        { Header: 'GHL_company_ID', accessor: 'GHL_company_ID' },
        { Header: 'GHL_location_ID', accessor: 'GHL_location_ID' },
        { Header: 'Plan_ID', accessor: 'Plan_ID' },
        { Header: 'install_status', accessor: 'install_status' },
      ],

      rows: List.map((data) => ({
        App_Name: superadmin_apps.filter((app) => app.app_id == data.App_ID)[0]?.name,
        App_ID: data.App_ID,
        GHL_USER_ID: data.GHL_USER_ID,
        GHL_company_ID: data.GHL_company_ID,
        GHL_location_ID: data.GHL_location_ID,
        Plan_ID: filterPlanId(data.Plan_ID),
        install_status: data.install_status ? "true" : "false",
      })),
    },
  }
}