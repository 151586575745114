/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function DataTableData() {
  const AppService = useAppServices()
  const navigate = useNavigate()
  const [List, setList] = useState([])
  const [Tokens, setTokens] = useState([])
  const [superadmin_apps, setsuperadmin_apps] = useState([])
  const [loader, setloader] = useState(true)

  const getAppCompanies = async () => {
    const { response } = await AppService.appinstalls.getTokens()
    console.log(response, 'getAppCompanies')
    if (response) {
      setList(response.data)
      setTokens(response.apptoken)
      setsuperadmin_apps(response.superadmin_apps)
      setloader(false)
    } else {
      setList([])
      setloader(false)
    }
  }
  const onLoad = () => {
    getAppCompanies()
  }
  const filterPlanId = (Plan_ID) => {
    const filter_monthly = superadmin_apps.filter((app) => app.monthly_id == Plan_ID)
    const filter_yearly = superadmin_apps.filter((app) => app.yearly_id == Plan_ID)
    if (filter_monthly[0]) {
      const filter_price = superadmin_apps.filter((app) => app.monthly_id == Plan_ID)[0]?.monthly_price
      return filter_price

    } else {
      const filter_price = superadmin_apps.filter((app) => app.yearly_id == Plan_ID)[0]?.yearly_price
      return filter_price

    }
  }
  const handleCopy = (value) => {

  }
  useEffect(onLoad, [])
  return {
    loader: loader,
    handleRefresh: onLoad,
    dataTableData: {
      columns: [
        { Header: 'App_Name', accessor: 'App_Name' },
        { Header: 'App_ID', accessor: 'App_ID' },
        { Header: 'Location Id', accessor: 'LocationId' },
        { Header: 'Company Id', accessor: 'CompanyId' },
        { Header: 'AccessToken', accessor: 'AccessToken' },
      ],

      rows: Tokens.map((data) => {
        console.log("Data app_id:", data.app_id);
        console.log("Tokens array:", List);



        return {
          App_Name: superadmin_apps.filter((app) => app.app_id == data.app_id)[0]?.name,
          App_ID: data.app_id,
          LocationId: data.location_id,
          CompanyId: data.company_id,
          AccessToken: <MDButton color="warning" size="small" variant="contained" onClick={handleCopy}>
            copy
          </MDButton>,
        };
      }),
    },
  }
}