/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import EditModal from '../Modals/Edit'

// 
export default function DataTableData() {
    const AppService = useAppServices()
    const { page_id } = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [pages, setPages] = useState([])
    const [loader, setloader] = useState(true)

    const getData = async () => {
        const { response, error } = await AppService.tooltip.get({

            query: `page_id=${page_id}`
        }
        )
        console.log(response, 'response')
        if (response) {
            setData(response.data)
            setPages(response.pages.map((page) => ({
                ...page,
                label: page.name,
                value: page._id,
            })))
            setloader(false)

        } else {
            setData([])
            setloader(false)
        }
    }
    const onLoad = () => {
        getData()
    }
    const handleDelete = async (id) => {
        const { response } = await AppService.tooltip.delete({
            query: `_id=${id}`,
        })
        if (response) onLoad()
    }
    useEffect(onLoad, [])
    return {
        loader: loader,
        data: data,
        pages: pages,
        handleRefresh: onLoad,
        TableData: {
            columns: [
                { headerName: 'name', field: 'name' },
                { headerName: 'Action', field: 'action' },
            ],

            rows: data?.map((data, index) => ({
                id: data._id,
                name: data.name,
                // pages: pages,
                // item: data,
                // handleRefresh: onLoad,
                action: data,
            })),
        },
    }
}
