/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// MD UI Dashboard PRO React components
import MDBox from 'components/MDBox'

// MD UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'
import borders from 'assets/theme/base/borders'

function DataTableBodyCell({ noBorder, align, children }) {
  const { light } = colors
  const { size } = typography
  const { borderWidth } = borders

  return (
    <MDBox
      component="td"
      textAlign={align}
      fontSize={size.sm}
      borderBottom={noBorder ? 'none' : `${borderWidth[1]} solid ${light.main}`}
      py={1.5}
      px={3}
    >
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: 'middle' }}
      >
        {children}
      </MDBox>
    </MDBox>
  )
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: 'left',
}

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right', 'center']),
}

export default DataTableBodyCell
