import MDButton from 'components/MDButton'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "500px",
    minHeight: '500px',
    height: 'auto',
    overflow: 'auto',
    bgcolor: 'background.paper',
    border: '2px  #000',
    boxShadow: 24,
    p: 4,
}
const ViewModel = ({ data }) => {
    const [openAddProduct, setOpenAddProduct] = useState(false)
    useEffect(async () => { }, [])
    function AddProduct({ open, onClose }) {

        return (
            <MDModal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MDBox sx={style}>
                    <MDBox display="flex" flexDirection="column" gap={3}>
                        <MDTypography textAlign="center" variant="button" sx={{ fontSize: '19px', fontWeight: '500', width: '100%' }} >
                            {data?.app_name}
                        </MDTypography>
                        <MDBox >
                            <MDTypography variant="button" sx={{ fontSize: '16px', fontWeight: '500' }} >
                                Description
                            </MDTypography>
                            <div dangerouslySetInnerHTML={{ __html: data?.description }} style={{ fontSize: '14px', fontWeight: '400' }} />
                        </MDBox>
                        <MDBox display="flex" justifyContent="space-between">
                            <MDTypography variant="button" sx={{ fontSize: '16px', fontWeight: '500' }} >
                                Title
                            </MDTypography>
                            <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '400' }} >
                                {data?.title}
                            </MDTypography>
                        </MDBox>
                        <MDBox display="flex" justifyContent="space-between">
                            <MDTypography variant="button" sx={{ fontSize: '16px', fontWeight: '500' }} >
                                App Type
                            </MDTypography>
                            <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '400' }} >
                                {data?.app_type}
                            </MDTypography>
                        </MDBox>
                        <MDBox display="flex" justifyContent="space-between">
                            <MDTypography variant="button" sx={{ fontSize: '16px', fontWeight: '500' }} >
                                Type
                            </MDTypography>
                            <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '400' }} >
                                {data?.type}
                            </MDTypography>
                        </MDBox>
                        <MDBox display="flex" justifyContent="space-between">
                            <MDTypography variant="button" sx={{ fontSize: '16px', fontWeight: '500' }} >
                                Company Id
                            </MDTypography>
                            <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '400' }} >
                                {data?.company_id}
                            </MDTypography>
                        </MDBox>
                        {
                            data?.type == "location" &&
                            <MDBox display="flex" justifyContent="space-between">
                                <MDTypography variant="button" sx={{ fontSize: '16px', fontWeight: '500' }} >
                                    Location Id
                                </MDTypography>
                                <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '400' }} >
                                    {data?.location_id}
                                </MDTypography>
                            </MDBox>
                        }
                        {
                            data?.loom_video &&
                            <MDBox display="flex" justifyContent="space-between">
                                <MDTypography variant="button" sx={{ fontSize: '16px', fontWeight: '500' }} >
                                    Loom Video
                                </MDTypography>
                                <MDButton
                                    variant="contained"
                                    color="info"
                                    size="small"

                                >
                                    <a href={data?.loom_video} style={{ color: "white" }} target='_blank'>View</a>
                                </MDButton>
                            </MDBox>
                        }
                    </MDBox>
                </MDBox>
            </MDModal>
        )
    }
    const closeAddProduct = () => {
        // if (subscription?._id)
        setOpenAddProduct(false)
    }
    const handlemodal = () => {
        setOpenAddProduct(true)
    }
    return (
        <>
            <MDButton
                variant="contained"
                color="warning"
                size="small"
                sx={{ marginLeft: 2 }}
                onClick={handlemodal}
            >
                View
            </MDButton>
            <AddProduct open={openAddProduct} onClose={closeAddProduct} />
        </>
    )
}

export default ViewModel
