import MDButton from 'components/MDButton'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Edit } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
} from '@mui/material'
import { useAppServices, useUploadImage } from 'hook/services'
import { useUserInfo } from 'context/user'
import FormField from 'components/FormField'
import MDInput from 'components/MDInput'
import Multiselect from '../CreateModal/components/Multiselect'
import RadioInput from 'components/Radio/RadioInput'
import Editor from './components/editor'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: '80vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  p: 4,
}
const EditModal = ({ data, handleRefresh, products, Categories }) => {
  const uploadImage = useUploadImage()
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const AppService = useAppServices()
  const [user] = useUserInfo()
  var axios = require('axios')
  const types = [
    {
      label: 'Workflow',
      value: 'workflow app',
    },
    {
      label: 'Funnel',
      value: 'funnel app',
    },
    {
      label: 'Both',
      value: 'workflow & funnel app',
    },
  ]
  // const integrationTypes = [
  //   {
  //     label: 'Api Key',
  //     value: 'key',
  //   },
  //   {
  //     label: 'Auth',
  //     value: 'login',
  //   },
  // ]
  const integration_type = [
    {
      label: 'Basic',
      value: 'basic',
    },
    {
      label: 'Iframe',
      value: 'iframe',
    },
    {
      label: 'Url',
      value: 'url',
    },
    // {
    //   label: 'Client ID / Clien Secret',
    //   value: 'dual_api_keys',
    // },
    // {
    //   label: 'O Auth',
    //   value: 'auth',
    // },
    // {
    //   label: 'Basic Auth',
    //   value: 'basic_auth',
    // },
    // {
    //   label: 'Out Bound',
    //   value: 'out_bound',
    // },
    // {
    //   label: 'Out Bound Auth',
    //   value: 'out_bound_auth',
    // },
  ]
  const status = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
    {
      label: 'Comming Soon',
      value: 'comming soon',
    },
  ]
  const timeline = [
    {
      label: '7 Days',
      value: '7 days',
    },
    {
      label: '14 Days',
      value: '14 days',
    },
    {
      label: '30 Days',
      value: '30 days',
    },
    {
      label: '60 Days',
      value: '60 days',
    },
    {
      label: '90 Days',
      value: '90 days',
    },
  ]
  const handlemodal = () => {
    setOpenAddProduct(true)
  }
  useEffect(async () => { }, [])
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false)
    const [editor, setEditor] = useState(data?.description)
    const [permisson_details, setpermisson_details] = useState(data?.permisson_details)

    const handleSubmit = async (e) => {
      e.preventDefault()
      setProcessing(true);
      const is_app_free = e.target.is_app_free.checked
      var logo_response = data.image
      if (e.target.logo.files[0]) {
        const temp_response = await uploadImage({
          file: e.target.logo.files[0],
          desiredPath: `app/logo/image`,
        })
        if (temp_response.response) {
          logo_response = temp_response.response.data
        }
      }
      const payload = {
        _id: data._id,
        name: e.target.name.value,
        slogan: e.target.slogan.value,
        description: editor,
        permisson_details: permisson_details,
        version: e.target.version.value,
        categories: JSON.parse(e.target.categories.value),
        site: e.target.site.value,
        logo: logo_response,
        user_id: user._id,
        is_app_free: is_app_free,
        price: !is_app_free ? e.target.price.value : null,
      }
      console.log(payload)

      const { response } = await AppService.app_listing.update({
        payload: payload,
      })
      console.log(response)
      if (response) {
        setProcessing(false)
        onClose()
        handleRefresh()
      }
    }
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      height: '80vh',
      overflow: 'auto',
      bgcolor: 'background.paper',
      border: '2px  #000',
      boxShadow: 24,
      p: 4,
    }
    function IN() {
      const [isAppFree, setIsAppFree] = useState(data.is_app_free)
      return (
        <>
          <MDBox sx={{ fontSize: '15px' }}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={isAppFree} name='is_app_free' onChange={(e) => setIsAppFree(e.target.checked)} />} label="Is It Free" />
            </FormGroup>
          </MDBox>
          {!isAppFree && (
            <MDBox display="flex" flexDirection="column">
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Price
              </MDTypography>
              <input type="number" defaultValue={data?.price} step="0.01" name="price" className='form-control' style={{ outline: 'none' }} />
              {/* <TextField
                id="outlined-number"
                label="Price"
                type="number"
                step=".01"
                defaultValue={data?.price}
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
              {/* <MDInput label="Price" type="number" defaultValue={data?.price} name="price" fullWidth step="0.01" /> */}
            </MDBox>
          )}
        </>
      )
    }
    return (
      <MDModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox>
          <MDBox component="form" onSubmit={handleSubmit} role="form" sx={style}>
            <MDTypography variant="h5" mb={2}>
              App
            </MDTypography>
            <MDBox mb={2} ml={1} display="flex" alignItems="center" >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" component="label" sx={{ color: '#fff' }}>
                  Logo +
                  <input hidden name="logo" type="file" />
                </Button>
              </Stack>
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1} >
                Name
              </MDTypography>
              <MDInput label="Name" name="name" fullWidth defaultValue={data?.name} />
            </MDBox>
            <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Categories
              </MDTypography>
              <Multiselect
                data={Categories}
                edit_data={data?.categories}
                isMulti={true}
                name="categories"
              />
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                App Slogan
              </MDTypography>
              <MDInput label="Slogan" name="slogan" fullWidth defaultValue={data?.slogan} />
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Description
              </MDTypography>
              <MDBox>
                <Editor
                  handleChange={(data) => {
                    console.log('html', data)
                    setEditor(data)
                  }}
                  data={editor}
                // {...props}
                />
              </MDBox>
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Permission Details
              </MDTypography>
              <MDBox>
                <Editor
                  handleChange={(data) => {
                    console.log('html', data)
                    setpermisson_details(data)
                  }}
                  data={permisson_details}
                // {...props}
                />
              </MDBox>
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1} >
                App Site
              </MDTypography>
              <MDInput label="App Site" placeholder="App Site" name="site" fullWidth defaultValue={data?.site} />
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Version
              </MDTypography>
              <MDInput label="Version" placeholder="Version" name="version" fullWidth defaultValue={data?.version} />
            </MDBox>
            {IN()}
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color="primary"
                type="button"
                sx={{ mt: 4, mb: 1 }}
                onClick={closeAddProduct}
              >
                close
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1, ml: 1 }}
                loading={processing}
                disabled={processing}
              >
                Edit
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    )
  }
  const closeAddProduct = () => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      <MDButton
        size="small"
        sx={{ marginRight: 2 }}
        color="info"
        variant="contained"
        onClick={handlemodal}
      >
        Edit
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  )
}

export default EditModal
