import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { TextareaAutosize } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  p: 4,
}
const DocsValidate = yup.object({
  name: yup.string().required().label('Name'),
})
function Category({ open, onClose, category }) {
  const uploadImage = useUploadImage()

  const [processing, setProcessing] = useState(false)
  const AppService = useAppServices()
  const handleSubmit = async (e) => {
    setProcessing(true)
    e.preventDefault()
    let image_url = category?.image

    // Check if a new image is provided
    if (e.target.image.files.length > 0) {
      image_url = await uploadImage({
        file: e.target.image.files[0],
        desiredPath: `design/logo/image`,
      })
        .then((res) => res.response.data)
        .catch((err) => null)
    }
    const payload = {
      name: e.target.name.value,
      description: e.target.description.value,
      image: image_url,
      type: 'super-admin',
    }
    if (category) {
      payload._id = category._id
      payload.type = category?._type
    }
    console.log(payload)
    const { response } = await AppService.agency_articles_category[
      category?._id ? 'updateDocs' : 'createDocs'
    ]({
      payload,
      toaster: true,
    })
    setProcessing(false)
    if (response) {
      onClose()
    }
  }
  const initstate = {
    name: category?.name || '',
    description: category?.description || '',
    image: category?.image || '',
  }

  const formik = useFormik({
    initialValues: { ...initstate },
    onSubmit: handleSubmit,
    validationSchema: DocsValidate,
  })

  return (
    <MDBox>
      <MDModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
          <MDTypography variant="h6">Create Docs Category</MDTypography>
          <MDBox my={2}>
            <MDInput
              type="text"
              label="Name"
              name="name"
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
              helperText={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
              success={formik.touched.name && !formik.errors.name}
              fullWidth
            />
          </MDBox>
          <MDBox my={2}>
            <MDInput type="file" name="image" fullWidth />
          </MDBox>
          <MDBox my={2}>
          <TextareaAutosize
            onChange={formik.handleChange}
            inputProps={{ onFocus: formik.handleBlur }}
            className="form-control"
            style={{ minHeight: '70px' }}
            placeholder="Description"
            value={formik.values.description}
            name="description"
          />
          </MDBox>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            className="brand-card-background"
            loading={processing}
            disabled={processing || !formik.isValid}
            sx={{ mt: 4, mb: 1 }}
            fullWidth
          >
            {category?.name ? 'Update' : 'Add'}
          </MDButton>
        </MDBox>
      </MDModal>
    </MDBox>
  )
}

export default Category
