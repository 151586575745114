import { Card, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useAppServices } from 'hook/services'
import { useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import Editor from 'components/editor'


const CreateValidate = yup.object({
    title: yup.string().required().label('Title'),
    category: yup.string().required().label('Category'),
    image: yup.string().required().label('Image'),
    text: yup.string().required().label('Text'),
})

function Create(props) {
    const AppService = useAppServices()
    const [processing, setProcessing] = useState(false)
    const [version_type, setversion_type] = useState('update')
    // const [categories, setCategories] = useState([])
    const navigate = useNavigate()
    const {
        state: { doc, SubCategory },
    } = useLocation()
    const [editor, setEditor] = useState(doc?.doc || null)
    console.log(SubCategory, 'SubCategory')
    const initState = {
        title: doc?.title || '',
        image: '',
        text: '',
    }
    const handleSubmit = async (formValues) => {
        setProcessing(true)
        let img = doc?.cover_img || ''

        if (formValues.image) {
            const form = new FormData()
            const file = formValues.image
            form.append('image', file, file.name)
            const upload = await AppService.utils.upload_image({ payload: form })
            img = upload.response?.data
        }

        const payload = {
            title: formValues.title,
            // category: categories.find((item) => item.value == formValues.category),
            category: SubCategory.category,
            sub_category: SubCategory._id,
            cover_img: img,
            doc: editor,
        }

        if (doc) {
            ; (payload._id = doc._id),
                (payload.version_type = version_type),
                (payload.version = doc.version)
        } else {
            payload.type = 'super-admin'
        }
        console.log(payload, 'payload')
        const { response } = await AppService.docs[doc ? 'updateDocs' : 'createDocs']({
            payload,
            toaster: true,
        })

        setProcessing(false)
        if (response?.data) navigate(-1)
    }

    const formik = useFormik({
        initialValues: { ...initState },
        onSubmit: handleSubmit,
    })
    const handleImageChange = (e) => {
        const file = e.target.files[0]
        formik.setFieldValue('image', file)
    }

    // const getDocs_Cat = async () => {
    //   const { response } = await AppService.docs.get_filter_cat({ query: 'type=super-admin' })
    //   if (response)
    //     setCategories(response.data.map((item) => ({ label: item.name, value: item._id })))
    // }

    // const onLoad = () => {
    //   getDocs_Cat()
    // }

    // useEffect(onLoad, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center  "
                        >
                            <MDTypography variant="h6" color="white">
                                Create
                            </MDTypography>
                            <MDTypography variant="h6" color="white">
                                version : {doc?.version || '1.0'}
                            </MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <Card sx={{ p: 4 }}>
                <MDBox
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                    display="flex"
                    justifyContent="center"
                >
                    <Grid item lg={8}>
                        <MDBox my={2}>
                            <MDInput
                                type="text"
                                label="Title"
                                name="title"
                                onChange={formik.handleChange}
                                inputProps={{ onFocus: formik.handleBlur }}
                                value={formik.values.title}
                                error={formik.touched.title && formik.errors.title}
                                helperText={formik.touched.title && formik.errors.title ? formik.errors.title : ''}
                                success={formik.touched.title && !formik.errors.title}
                                fullWidth
                            />
                        </MDBox>
                        <MDBox my={2}>
                            <MDTypography variant="h6">Add Cover Image</MDTypography>
                            <MDButton variant="outlined" color="info" component="label">
                                Choose File
                                <MDInput
                                    type="file"
                                    name="image"
                                    onChange={handleImageChange}
                                    inputProps={{ onFocus: formik.handleBlur }}
                                    fullWidth
                                />
                            </MDButton>
                        </MDBox>
                        <MDBox my={2}>
                            <Editor
                                handleChange={(data) => {
                                    console.log('html', data)
                                    setEditor(data)
                                }}
                                data={editor}
                                {...props}
                            />
                        </MDBox>
                        <MDBox my={2}>
                            {doc ? (
                                <MDBox display="flex" justifyContent="flex-end">
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        onClick={() => setversion_type('update')}
                                        type="submit"
                                        className="brand-card-background"
                                        loading={version_type == 'update' ? processing : false}
                                        disabled={processing || !formik.isValid}
                                        sx={{ mt: 4, mb: 1, mr: 2 }}
                                    >
                                        Update
                                    </MDButton>

                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        onClick={() => setversion_type('published')}
                                        type="submit"
                                        className="brand-card-background"
                                        loading={version_type == 'published' ? processing : false}
                                        disabled={processing || !formik.isValid}
                                        sx={{ mt: 4, mb: 1 }}
                                    >
                                        Published New Version
                                    </MDButton>
                                </MDBox>
                            ) : (
                                <MDButton
                                    variant="gradient"
                                    color="info"
                                    type="submit"
                                    className="brand-card-background"
                                    loading={processing}
                                    disabled={processing || !formik.isValid}
                                    sx={{ mt: 4, mb: 1 }}
                                    fullWidth
                                >
                                    Create
                                </MDButton>
                            )}
                        </MDBox>
                    </Grid>
                </MDBox>
            </Card>
        </DashboardLayout>
    )
}

export default Create
