/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import EditModal from '../Modals/Edit'
import Swal from 'sweetalert2'

// 
export default function DataTableData() {
    const AppService = useAppServices()
    const params = useParams()
    const navigate = useNavigate()
    const [languages, setLanguages] = useState([])
    const [loader, setloader] = useState(true)

    const getLanguages = async () => {
        const { response, error } = await AppService.languages.get({

            query: ``
        }
        )
        console.log(response, 'response')
        if (response) {
            setLanguages(response.data)
            setloader(false)

        } else {
            setLanguages([])
            setloader(false)
        }
    }
    const onLoad = () => {
        getLanguages()
    }
    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Warning',
            text: 'Are you sure you want to delete?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Yes',
        }).then(async (res) => {
            if (!res.isConfirmed) return ''
            const { response } = await AppService.languages.delete({
                query: `_id=${id}`,
            })
            if (response) onLoad()
        })
    }
    useEffect(onLoad, [])
    return {
        loader: loader,
        languages: languages,
        handleRefresh: onLoad,
        TableData: {
            columns: [
                { Header: 'name', accessor: 'name' },
                { Header: 'Action', accessor: 'action' },
            ],

            rows: languages?.map((data, index) => ({
                name: data.name,
                action: (
                    <div className='d-flex'>
                        <EditModal
                            handleRefresh={onLoad}
                            editData={data}
                        />
                        <MDButton
                            variant="gradient"
                            color="warning"
                            sx={{ marginLeft: 2 }}
                            onClick={() => navigate(`./${data._id}`, { state: data })}
                        >
                            view
                        </MDButton>
                        <MDBox>
                            <MDButton
                                variant="contained"
                                color="error"
                                size="small"
                                sx={{ marginLeft: 2 }}
                                onClick={() => handleDelete(data._id)}
                            >
                                Delete
                            </MDButton>
                        </MDBox>
                    </div>
                ),
            })),
        },
    }
}
