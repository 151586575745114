import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import EditModal from '../Modals/Edit'
import { useAppServices } from 'hook/services';
import "./style.css"
function updateRowPosition(initialIndex, newIndex, rows) {
    return new Promise((resolve) => {
        setTimeout(
            () => {
                const rowsClone = [...rows];
                const row = rowsClone.splice(initialIndex, 1)[0];
                rowsClone.splice(newIndex, 0, row);
                resolve(rowsClone);
            },
            Math.random() * 500 + 100,
        ); // simulate network latency
    });
}

export default function RowOrderingGrid({ data, initialLoadingState, handleRefresh, pages }) {
    const AppService = useAppServices()
    const handleDelete = async (id) => {
        const { response } = await AppService.tooltip.delete({
            query: `_id=${id}`,
        })
        if (response) handleRefresh()
    }

    const [rows, setRows] = React.useState(data.rows);
    const [columns, setColumns] = React.useState(data.columns);
    const [loader, setLoader] = React.useState(true);
    const [RowChange, setRowChange] = React.useState(false);
    const [loading, setLoading] = React.useState(loader);

    React.useEffect(() => {
        const temp_col = [
            { headerName: 'name', field: 'name', width: 300 },

            {
                headerName: 'Action',
                width: 400,
                field: 'action',
                renderCell: (params) => {
                    return (

                        <div className='d-flex'>
                            <MDBox>

                                <EditModal
                                    pages={pages}
                                    handleRefresh={handleRefresh}
                                    data={params.value}
                                />
                            </MDBox>

                            <MDBox>
                                <MDButton
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    sx={{ marginLeft: 2 }}
                                    onClick={() => handleDelete(params.value._id)}
                                >
                                    Delete
                                </MDButton>
                            </MDBox>
                        </div>
                    )
                }
            }
        ]
        setLoader(false)
        setColumns(temp_col)
        setRows(data.rows);
    }, [data]);

    React.useEffect(() => {
        setLoading(initialLoadingState);
    }, [initialLoadingState]);

    const handleRowOrderChange = async (params) => {
        setLoading(true);
        const newRows = await updateRowPosition(
            params.oldIndex,
            params.targetIndex,
            rows,
        );
        console.log(newRows, 'newRows')
        setRows(newRows);
        setRowChange(true)
        setLoading(false);
    };
    const handleUpdateIndex = async () => {
        const { response } = await AppService.tooltip.update_index({
            payload: rows,
        })
        if (response) {
            setRowChange(false)
            handleRefresh()
        }
    }
    return (
        <MDBox>
            <MDBox display="flex" justifyContent="flex-end" mb={3}>
                <MDButton className={RowChange ? "index-change-button" : ""} color="info" size="small" variant="contained" onClick={handleUpdateIndex}>
                    Save
                </MDButton>
            </MDBox>
            <div style={{ height: 400, width: '100%' }}>
                <DataGridPro
                    {...data}
                    columns={columns}
                    loading={loader}
                    rows={rows}
                    rowReordering
                    onRowOrderChange={handleRowOrderChange}
                />
            </div>
        </MDBox>
    );
}