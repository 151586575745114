import MDButton from 'components/MDButton'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import { useUserInfo } from 'context/user'
import MDInput from 'components/MDInput'
import { useParams } from 'react-router-dom'
import Multiselect from '../../MultiSelect'
import Editor from '../../editor'

const CreateModal = ({ pages, handleRefresh, data }) => {
    const uploadImage = useUploadImage()
    const { page_id } = useParams()
    const [openAddProduct, setOpenAddProduct] = useState(false)
    const AppService = useAppServices()
    const [user] = useUserInfo()
    var axios = require('axios')
    const handlemodal = () => {
        setOpenAddProduct(true)
    }
    const ToolTipTypes = [
        {
            label: "Modal",
            value: "Modal",
        },
        {
            label: "Tool Tip",
            value: "Tool Tip",
        },
        {
            label: "Selector Element",
            value: "Selector Element",
        },
    ]
    const Positions = [
        {
            label: "Top Left",
            value: "Top Left",
        },
        {
            label: "Bottom Left",
            value: "Bottom Left",
        },
        {
            label: "Top Right",
            value: "Top Right",
        },
        {
            label: "Bottom Right",
            value: "Bottom Right",
        },
        {
            label: "Center",
            value: "Center",
        },
    ]
    const Directions = [
        {
            label: "Left",
            value: "Left",
        },
        {
            label: "Right",
            value: "Right",
        },
        {
            label: "Top",
            value: "Top",
        },
        {
            label: "Bottom",
            value: "Bottom",
        },
    ]
    const Selectors = [
        {
            label: "Left",
            value: "Left",
        },
        {
            label: "Right",
            value: "Right",
        },
        {
            label: "Top",
            value: "Top",
        },
        {
            label: "Bottom",
            value: "Bottom",
        },
    ]
    useEffect(async () => { }, [])
    function AddProduct({ open, onClose }) {
        const [processing, setProcessing] = useState(false)
        const [toolTipType, setToolTipType] = useState({})
        const [content, setContent] = useState('')

        const handleSubmit = async (e) => {
            e.preventDefault()
            setProcessing(true);
            var position = null
            var direction = null
            var selector = null
            if (toolTipType.value == "Modal") {
                position = JSON.parse(e.target.position.value)
            }
            if (toolTipType.value == "Selector Element" || toolTipType.value == "Tool Tip") {
                direction = JSON.parse(e.target.direction.value)
            }
            if (toolTipType.value == "Selector Element" || toolTipType.value == "Tool Tip") {
                selector = e.target.selector.value
            }
            const page = pages?.filter((item) => item._id == page_id)[0]
            const payload = {
                page: page,
                page_id: page_id,
                name: e.target.name.value,
                content: content,
                position: position,
                direction: direction,
                selector: selector,
                tool_tip_type: toolTipType,
                index: data.length

            }
            console.log(payload)
            const { response } = await AppService.tooltip.create({
                payload: payload,
            })
            console.log(response)
            if (response) {
                setProcessing(false)
                onClose()
                handleRefresh()
            }
        }
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            height: "auto",
            maxHeight: '80vh',
            overflow: "auto",
            bgcolor: 'background.paper',
            border: '2px  #000',
            boxShadow: 24,
            p: 4,
        }
        return (

            <MDModal open={open} onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <MDBox>
                    <MDBox component="form" onSubmit={handleSubmit} role="form" sx={style}>
                        <MDTypography variant="h5" mb={2}>
                            Create Tool Tip
                        </MDTypography>
                        <MDBox>
                            <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                                Name
                            </MDTypography>
                            <MDInput label="Name" name="name" fullWidth />
                        </MDBox>
                        {/* <MDBox sx={{ fontSize: '15px' }}>
                            <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                                Page
                            </MDTypography>
                            <Multiselect
                                data={pages}
                                edit_data={[]}
                                isMulti={false}
                                name="page"
                            />
                        </MDBox> */}
                        <MDBox sx={{ fontSize: '15px' }}>
                            <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                                Type
                            </MDTypography>
                            <Multiselect
                                data={ToolTipTypes}
                                edit_data={[]}
                                isMulti={false}
                                name="tool_tip_type"
                                onChange={(e) => setToolTipType(e)}
                            />
                        </MDBox>
                        {
                            toolTipType?.value == "Modal" &&
                            <>

                                <MDBox sx={{ fontSize: '15px' }}>
                                    <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                                        Position
                                    </MDTypography>
                                    <Multiselect
                                        data={Positions}
                                        edit_data={[]}
                                        isMulti={false}
                                        name="position"
                                    />
                                </MDBox>
                                <MDBox>
                                    <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                                        Content
                                    </MDTypography>
                                    <Editor
                                        handleChange={(data) => {
                                            console.log('html', data)
                                            setContent(data)
                                        }}
                                        data={content}
                                    />
                                </MDBox>
                            </>
                        }
                        {
                            toolTipType?.value == "Tool Tip" &&
                            <>

                                <MDBox sx={{ fontSize: '15px' }}>
                                    <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                                        Direction
                                    </MDTypography>
                                    <Multiselect
                                        data={Directions}
                                        edit_data={[]}
                                        isMulti={false}
                                        name="direction"
                                    />
                                </MDBox>
                                <MDBox sx={{ fontSize: '15px' }}>
                                    <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                                        Selector
                                    </MDTypography>
                                    <MDInput label="Selector" name="selector" fullWidth />
                                </MDBox>
                                <MDBox>
                                    <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                                        Content
                                    </MDTypography>
                                    <Editor
                                        handleChange={(data) => {
                                            console.log('html', data)
                                            setContent(data)
                                        }}
                                        data={content}
                                    />
                                </MDBox>
                            </>
                        }
                        {toolTipType?.value == "Selector Element" &&
                            <>

                                <MDBox sx={{ fontSize: '15px' }}>
                                    <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                                        Direction
                                    </MDTypography>
                                    <Multiselect
                                        data={Directions}
                                        edit_data={[]}
                                        isMulti={false}
                                        name="direction"
                                    />
                                </MDBox>
                                <MDBox sx={{ fontSize: '15px' }}>
                                    <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                                        Selector
                                    </MDTypography>
                                    <MDInput label="Selector" name="selector" fullWidth />
                                </MDBox>
                                <MDBox>
                                    <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                                        Content
                                    </MDTypography>
                                    <Editor
                                        handleChange={(data) => {
                                            console.log('html', data)
                                            setContent(data)
                                        }}
                                        data={content}
                                    />
                                </MDBox>
                            </>
                        }
                        <MDBox display="flex" justifyContent="flex-end">
                            <MDButton
                                variant="gradient"
                                color="primary"
                                type="button"
                                sx={{ mt: 4, mb: 1 }}
                                onClick={closeAddProduct}
                            >
                                close
                            </MDButton>
                            <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                sx={{ mt: 4, mb: 1, ml: 1 }}
                                loading={processing}
                                disabled={processing}
                            >
                                Create
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </MDModal>
        )
    }
    const closeAddProduct = () => {
        // if (subscription?._id)
        setOpenAddProduct(false)
    }
    return (
        <>
            <MDButton color="white" size="small" variant="contained" onClick={handlemodal}>
                Create
            </MDButton>
            <AddProduct open={openAddProduct} onClose={closeAddProduct} />
        </>
    )
}

export default CreateModal
