import MDButton from 'components/MDButton'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Edit } from '@mui/icons-material'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
} from '@mui/material'
import { useAppServices, useUploadImage } from 'hook/services'
import { useUserInfo } from 'context/user'
import FormField from 'components/FormField'
import MDInput from 'components/MDInput'
import Multiselect from './components/Multiselect'
import { useAgencyInfo } from 'context/agency'

const CreateModal = ({ products, handleRefresh }) => {
  const [agency] = useAgencyInfo()

  const uploadImage = useUploadImage()
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const AppService = useAppServices()
  const [user] = useUserInfo()
  var axios = require('axios')
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px  #000',
    boxShadow: 24,
    p: 4,
  }
  const permissions = [
    {
      label: 'Agent',
      value: 'agent',
    },
    {
      label: 'Account',
      value: 'account',
    },
    {
      label: 'Superadmin',
      value: 'superadmin',
    },
  ]
  const handlemodal = () => {
    setOpenAddProduct(true)
  }
  useEffect(async () => {
    console.log(agency, 'etststststtst')
  }, [])
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false)

    const handleSubmit = async (e) => {
      e.preventDefault()
      // setProcessing(true);

      const data = {
        name: e.target.name.value,
        email: e.target.email.value,
        password: e.target.password.value,
        type: 'super_admin_team',
        agency_id: agency._id,
      }
      const payload = {
        data: data,
      }
      console.log(payload)

      const { response } = await AppService.auth.CreateTeam({
        payload: payload,
      })
      console.log(response)
      if (response) {
        setProcessing(false)
        onClose()
        handleRefresh()
      }
    }
    return (
      <MDModal open={open} onClose={onClose} width={440} >
        <MDBox>
          <MDBox component="form" onSubmit={handleSubmit} role="form" sx={style}>
            <MDTypography variant="h5" mb={2}>
              Create Team User
            </MDTypography>

            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Name
              </MDTypography>
              <MDInput label="Name" placeholder="Name" name="name" fullWidth />
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Email
              </MDTypography>
              <MDInput label="Email" placeholder="Email" name="email" fullWidth />
            </MDBox>
            <MDBox>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                Password
              </MDTypography>
              <MDInput
                label="Password"
                type="password"
                placeholder="Password"
                name="password"
                fullWidth
              />
            </MDBox>

            {/* <MDBox sx={{ fontSize: '15px' }}>
              <MDTypography variant="button" sx={{ fontSize: '14px', fontWeight: '500' }} mb={1}>
                User type
              </MDTypography>
              <Multiselect data={permissions} edit_data={[]} isMulti={true} name="role" />
            </MDBox> */}

            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color="primary"
                type="button"
                sx={{ mt: 4, mb: 1 }}
                onClick={closeAddProduct}
              >
                close
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1, ml: 1 }}
                loading={processing}
                disabled={processing}
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    )
  }
  const closeAddProduct = () => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      <MDButton color="white" variant="contained" onClick={handlemodal}>
        Create
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  )
}

export default CreateModal
