
import Home from './Home'
import ToolTip from './tooltip'

const routes = [
  {
    type: 'collapse',
    key: 'home',
    route: '/',
    component: <Home />,
  },
  {
    type: 'collapse',
    key: 'home',
    route: '/:page_id',
    component: <ToolTip />,
  },
]

export default routes
