/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'

export default function DataTableData() {
  const AppService = useAppServices()
  const [List, setList] = useState([])
  const [SSO, setSSO] = useState([])
  const [superadmin_apps, setsuperadmin_apps] = useState([])
  const [loader, setloader] = useState(true)

  const getAppCompanies = async () => {
    const { response } = await AppService.appinstalls.getsso()
    console.log(response, 'getAppCompanies')
    if (response) {
      setList(response.data)
      setSSO(response.SSO)
      setsuperadmin_apps(response.superadmin_apps)
      setloader(false)
    } else {
      setList([])
      setloader(false)
    }
  }
  const onLoad = () => {
    getAppCompanies()
  }
  useEffect(onLoad, [])
  return {
    loader: loader,
    handleRefresh: onLoad,
    dataTableData: {
      columns: [
        { Header: 'App_Name', accessor: 'App_Name' },
        { Header: 'User_id', accessor: 'User_id' },
        { Header: 'userName', accessor: 'userName' },
        { Header: 'email', accessor: 'email' },
        { Header: 'role', accessor: 'role' },
        { Header: 'Location Id', accessor: 'LocationId' },
        { Header: 'Company Id', accessor: 'CompanyId' },
        { Header: 'planId', accessor: 'planId' },
        { Header: 'createdAt', accessor: 'createdAt' },
      ],

      rows: SSO.map((data) => {
        console.log("Data app_id:", data.app_id);
        console.log("Tokens array:", List);



        return {
          App_Name: superadmin_apps.filter((app) => app.monthly_id == data.planId || app.yearly_id == data.planId)[0]?.name,
          User_id: data.userId,
          userName: data.userName,
          email: data.email,
          role: data.role,
          LocationId: data.activeLocation,
          CompanyId: data.companyId,
          planId: data.planId,
          createdAt: data.createdAt,
        };
      }),
    },
  }
}