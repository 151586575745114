/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import EditModal from '../Modals/Edit'

// 
export default function DataTableData({ activeCateory }) {
    const AppService = useAppServices()
    const { languageId } = useParams()

    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [loader, setloader] = useState(true)
    // const [skip, setSkip] = useState(0)
    const RecurciveCalling = () => {
        getLanguages()
    }
    const getLanguages = async () => {
        const { response, error } = await AppService.docs.get_filter({

            query: `type=super-admin&category=${activeCateory}`
        }
        )
        console.log(response, 'response')
        console.log(languageId, 'languageId')
        if (response) {
            setloader(true)

            setData(response.data)
            // setSkip(response.skip)
            setloader(false)

        } else {
            // setData([])
            setloader(false)
        }
    }
    const onLoad = () => {
        getLanguages()
    }
    const handleDelete = async (id) => {
        const { response } = await AppService.languages.delete({
            query: `_id=${id}`,
        })
        if (response) onLoad()
    }
    useEffect(onLoad, [activeCateory])
    return {
        loader: loader,
        languages: data,
        // skip: skip,
        handleRefresh: onLoad,
        TableData: {
            columns: [
                { Header: 'name', accessor: 'name' },
                { Header: 'Action', accessor: 'action' },
            ],

            rows: data?.map((data, index) => ({
                name: data.title,
                action: (

                    <div className='d-flex'>
                        <EditModal
                            handleRefresh={onLoad}
                            data={data}
                        />
                        <MDBox>
                            {/* <MDButton
                                variant="contained"
                                color="error"
                                size="small"
                                sx={{ marginLeft: 2 }}
                                onClick={() => handleDelete(data._id)}
                            >
                                Delete
                            </MDButton> */}
                        </MDBox>
                    </div>
                ),
            })),
        },
    }
}
