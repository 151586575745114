import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";
// import SoftTypography from 'components/SoftTypography'
// import MDAvatar from 'components/MDAvatar'

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
// import burceMars from 'assets/images/bruce-mars.jpg'
// import backgroundImage from 'assets/images/bg-profile.jpeg'
// import { useUserInfo } from 'context/user'

function TicketTabs({ elements }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  // const [user] = useUserInfo()
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item  xs={4} >
          <AppBar position="static">
            <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
              <Tab
              sx={{maxWidth:'9rem !important'}}
                label="Category"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    confirmation_number
                  </Icon>
                }
              />
              <Tab
              sx={{maxWidth:'9rem !important'}}
                label="Type"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    note
                  </Icon>
                }
              />
               <Tab
              sx={{maxWidth:'9rem !important'}}
                label="Group"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    group
                  </Icon>
                }
              />
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      {elements[tabValue]}
    </>
  );
}

// Typechecking props for the TicketTabs
TicketTabs.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default TicketTabs;
