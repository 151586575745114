import { Card, Divider, Grid, IconButton, Switch } from '@mui/material'
import Header from './components/Tabs'
import NewApp from './NewApp'
import UpdateApp from './UpdateApp'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
function index() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container mt={10}>
          <Grid item xs={12}>
            <Header elements={[<NewApp />, <UpdateApp />]} />
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  )
}

export default index
